<template>
  <div class="invite-page">
    <Header :title="'Mask'" :subTitleColor="'#fff'" :subTitleBgColor="'#000'" :bgColor="'#000'" />

    <div class="tabs">
      <div class="box">
        <van-tabs v-model="active" color="rgba(253, 32, 132, 1)" title-inactive-color="rgb(153,153,153)"
          title-active-color="#ffffff" background="transparent">
          <van-tab :title="$t('invite.invite')" :name="1"></van-tab>
          <van-tab :title="$t('invite.recommendationReward')" :name="2"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="invite-info" v-if="active == 1">
      <!--邀请人数-->
      <div class="invite-top">
        <div class="top-title">{{ $t('invite.inviteNum') }}</div>
        <div class="top-number">{{ inviteNum }}</div>
      </div>

      <!--邀请步骤-->
      <div class="invite-center">
        <div class="center-title">怎么邀请好友</div>
        <div class="center-step">
          <div class="circular">
            <img src="@/assets/img/invite/invite-wallet.png" />
          </div>
          <img src="@/assets/img/invite/invite-arrow.png" />
          <div class="circular">
            <img src="@/assets/img/invite/invite-share.png" />
          </div>
          <img src="@/assets/img/invite/invite-arrow.png" />
          <div class="circular">
            <img src="@/assets/img/invite/invite-user.png" />
          </div>
        </div>
        <div class="invite-word">
          <div>
            <p>登陆面具</p>
            <p>复制链接</p>
          </div>
          <div>
            <p>分享链接</p>
          </div>
          <div>
            <p>接收来自</p>
            <p>邀请用户</p>
            <p>的返佣</p>
          </div>
        </div>
      </div>
      <div class="invite-copy">
        <div @click="copyInviteLink">{{ $t('invite.inviteLink') }}</div>
        <div class="copyCode" @click="copyInviteCode">{{ $t('invite.copyInviteCode') }} : {{ myInviteCode }}</div>
      </div>

      <div class="invite-box">
        <div class="invite-title">{{ $t('invite.inviteCodeTip') }}</div>
        <div class="invite-addr">
          <input type="text" v-model="bandInviteCode" :disabled="readStatus"
            :placeholder="$t('invite.invitePlaceHolder')" />
        </div>
        <div class="save-btn" v-if="!userInfo.inviteId" @click="handleBandInviteCode">{{ $t('invite.bind') }}</div>
      </div>

    </div>
    <!-- 推荐奖励  以下部分class复用上面invite样式 -->
    <div class="invite-info reward-info" v-if="active == 2">
      <!--奖励金额-->
      <div class="invite-top">
        <div class="top-title">{{ $t('invite.rewardAmount') }}</div>
        <div class="top-number">{{ availableAmount }} USDT</div>
      </div>

      <div class="invite-copy">
        <div :class="{'forbid': !withdrawSwitch}" @click="openActionSheet">{{ $t('invite.withdrawReward') }}</div>
        <div class="copyCode" @click="$router.push({ path: '/withdrawRecord' })">{{ $t('invite.withdrawRecord') }}</div>
      </div>

      <div class="rewardRecord">
        <div class="title">{{ $t('invite.rewardRecord') }}</div>
        <van-list ref="list" v-model="loading" :immediate-check="false" :finished="finished"
          :finished-text="$t('tip.noMore')" @load="onReachBottom">
          <div class="item" v-for="(item, index) in rewardList" :key="index">
            <div class="row">
              <div class="id">ID:{{ item.invitedUserId }}</div>
              <div class="amount">+{{ item.amount }} USDT</div>
            </div>
            <div class="row">
              <div class="text">{{ item.type === 10 ? $t('invite.videoAuthentication') : $t('invite.selectedComments') }}
              </div>
              <div class="date">{{ $moment(item.createdAt).format('YYYY-MM-DD HH:mm') }}</div>
            </div>
          </div>
        </van-list>
      </div>
      <van-action-sheet v-model="showActionSheet">
        <div class="actionSheetContent">
          <div class="title">{{ $t('invite.withdrawal') }}</div>
          <div class="label">{{ $t('invite.withdrawalAmount') }}：</div>
          <div class="value">{{ availableAmount }} USDT</div>
          <div class="label">{{ $t('invite.withdrawalPublicChain') }}：</div>
          <div class="value">BSC</div>
          <div class="label">{{ $t('invite.withdrawAddress') }}：</div>
          <div class="inputBox">
            <input v-model="withdrawAddress" type="text" :placeholder="$t('invite.inputWithdrawAddress')">
          </div>
          <van-button class="confirmBtn" :disabled="this.availableAmount===0" @click="applyWithdraw">{{ $t('common.confirm') }}</van-button>
        </div>
      </van-action-sheet>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Header from '@/components/Header.vue'
import {
  queryInviteInfo,
  updateInviteInfo,
  queryRerowdList,
  queryTotalReward,
  applyWithdraw,
  getWithdrawSwitch
} from '@/service/user'

export default {
  components: { Header },
  data() {
    return {
      showActionSheet: false,
      active: 1,
      readStatus: false,
      bandInviteCode: '',
      inviteNum: 0,
      inviteCode: '',
      loading: false,
      finished: false,
      pageSize: 5,
      pageNum: 1,
      total: 0,
      withdrawAddress: '',
      rewardList: [],
      totalReward: 0,
      availableAmount: 0,
      withdrawSwitch: false
    }
  },
  computed: {
    ...mapState(['userInfo']),
    myInviteCode() {
      return this.userInfo.inviteCode ? this.userInfo.inviteCode : this.inviteCode
    },
  },
  methods: {
    ...mapActions(['changeUserInfo']),
    async init() {
      await this.getInviteInfo();
      this.getRerowdList();
      this.getTotalReward();
      this.getWithdrawSwitch()
    },
    openActionSheet() {
      if (!this.withdrawSwitch) {
        this.$toast(this.$t('invite.withdrawClosed'))
        return
      }
      this.showActionSheet = true
    },
    async getInviteInfo() {
      const resp = await queryInviteInfo()
      if (resp.code !== 200) return
      this.inviteNum = resp.data.inviteCount
      this.inviteCode = resp.data.inviteCode
      // @todo 该接口需要返回绑定的邀请码,如果有this.readStatus = true
      if (resp.data.inviterCode) {
        this.bandInviteCode = resp.data.inviterCode
        this.readStatus = true
      }

    },
    copyInviteLink() {
      this.$gbUtils.handleCopy(window.location.origin + '/#/home?inviteCode=' + this.inviteCode)
    },
    copyInviteCode() {
      this.$gbUtils.handleCopy(this.inviteCode)
    },
    async handleBandInviteCode() {
      if (!this.bandInviteCode) {
        this.$toast(this.$t('invite.bindTip'))
        return
      }
      const params = {
        "inviteCode": this.bandInviteCode
      }
      const resp = await updateInviteInfo(params)
      if (resp.code === 200) {
        this.readStatus = true
        this.userInfo.inviteId = resp.data.inviteId
        await this.changeUserInfo(this.userInfo)
        this.$toast(this.$t('invite.bindSuccess'))
      } else {
        this.$toast(resp.msg)
      }
    },
    async getTotalReward() {
      const resp = await queryTotalReward()
      if (resp.code !== 200) return
      this.totalReward = resp.data.totalAmount
      this.availableAmount = resp.data.availableAmount
    },
    async onReachBottom() {
      console.log('onReachBottom ....')
      if (this.pageNum * this.pageSize < this.total) {
        this.pageNum++
      } else {
        this.finished = true;
        return
      }
      this.getRerowdList()
    },
    async getRerowdList() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      this.loading = true
      const resp = await queryRerowdList(params)
      this.loading = false
      if (resp.code === 200) {
        this.total = resp.data.total
        if (this.pageNum === 1) {
          this.rewardList = resp.data.rows
        } else {
          this.rewardList.push(...resp.data.rows)
        }
        this.finished = this.pageNum * this.pageSize >= this.total;
      }
    },
    async getWithdrawSwitch() {
      const params = {
        paramType:"sc_withdraw_switch"
      }
      const resp = await getWithdrawSwitch(params)
      if (resp.success) {
        this.withdrawSwitch = resp.data.configValue === '1'
      }
    },
    async applyWithdraw() {
      if (!this.withdrawAddress) {
        return this.$toast(this.$t('invite.inputWithdrawAddress'));
      }
      if (!this.isValidBSCAddress(this.withdrawAddress)) {
        return this.$toast(this.$t('invite.addressVerify'));
      }
      const params = {
        amount: this.availableAmount,
        chain: "BSC",
        address: this.withdrawAddress
      }
      const resp = await applyWithdraw(params);
      if (resp.code === 200) {
        this.$toast('申请成功');
        this.showActionSheet = false;
        this.getTotalReward();
      }
    },
    isValidBSCAddress(address) {
      address = address.trim();
      if (address.length !== 42) {
        return false;
      }
      if (!address.match(/^0x[0-9a-fA-F]+$/)) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.init();

  }
}
</script>

<style lang="scss" scoped>
.invite-page {
  font-size: 12px;
  height: 100%;
  background: #000000;
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  .tabs {
    padding-left: 10px;
    background-color: rgb(0, 0, 0);
    margin-bottom: 20px;

    .box {
      width: 50%;
    }
  }

  .invite-info {
    background: #14151e;
    border-radius: 10px 10px 0 0;
    flex: 1;
    overflow-y: auto;

    .invite-top {
      width: 100%;
      height: 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 20px;

      div {
        width: 100%;
      }

      .top-title,
      .top-number {
        font-size: 14px;
        font-weight: 600;
      }

      .top-number {
        background: rgba(82, 71, 116, 0.2);
        text-align: center;
        height: 60px;
        line-height: 60px;
        font-size: 40px;
        color: #FD2084;
        font-weight: 600;
        border-radius: 4px;
      }

      .top-title {
        padding-bottom: 20px;
      }
    }

    .invite-center {
      height: 175px;
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      color: #fff;

      &>div {
        display: flex;
        width: 100%;
        text-align: center;
        height: 40px;
        align-items: center;
      }

      .center-title {
        font-size: 14px;
        font-weight: 600;
        padding-left: 20px;
      }

      .center-step {
        display: flex;
        justify-content: space-around;
        padding: 0 30px;

        div {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .circular {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #8A73D0;
        }

      }

      .invite-word {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        align-items: baseline;
        padding: 10px 43px 0 43px;

        div {
          p {
            display: flex;
            justify-content: flex-start;
            padding-bottom: 3px;
          }
        }
      }



    }

    .invite-bottom {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      background: $themeMainBg;
      align-items: center;
      border-radius: 12px;
      padding: 10px;

      div {
        width: 100%;
        display: flex;
        padding: 20px 10px;
        color: rgba(255, 255, 255, 0.6);
        flex-wrap: wrap;

        p {
          display: flex;
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .bottom-title {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
      }
    }

    .invite-copy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px;

      &>div {
        display: flex;
        justify-content: center;
        background: #8A73D0;
        border-radius: 4px;
        width: 48%;
        height: 38px;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
      }

      .copyCode {
        background: #FD2084;
      }
    }

    .invite-box {
      width: 100%;
      background: #14151E;

      .invite-title {
        font-size: 14px;
        padding: 10px 0 0 20px;
      }

      .invite-addr {
        height: 50px;
        margin-top: 10px;
        width: calc(100% - 40px);
        margin-left: 20px;

        input {
          width: 100%;
          background: #282A3B;
          padding-left: 10px;
          border-radius: 5px;
          border: none;
          font-size: 14px;
          color: #797a84;
        }
      }

      .invite-offical {
        font-size: 12px;
        margin-top: 20px;
        justify-content: flex-start;
        width: 85%;
      }

      .offical-address {
        font-size: 12px;
        margin-top: 5px;
        justify-content: flex-start;
        align-items: center;
        width: 85%;
      }

      div {
        display: flex;
        width: 100%;
      }

      .save-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 38px;
        font-size: 13px;
        font-weight: 600;
        border-radius: 4px;
        background: #8A73D0;
        margin: 20px auto;
      }
    }
  }

  .reward-info {
    .invite-top {
      height: unset;
    }

    .invite-copy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px;

      &>div {
        display: flex;
        justify-content: center;
        background: #8A73D0;
        border-radius: 4px;
        width: 48%;
        height: 38px;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
      }

      .copyCode {
        //background:
        //  rgba(44, 51, 65, 1);
        background: #FD2084;
      }
      .forbid {
        background: #2c3341;
        color: #fff;
      }
    }

    .rewardRecord {
      padding: 0 20px;
      padding-top: 40px;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .item {
        padding: 25px 0;
        border-bottom: 1px solid rgba(29, 30, 43, 1);

        .row {
          display: flex;
          font-size: 14px;
          margin-bottom: 15px;


          &:last-child {
            margin-bottom: 0;
          }

          .id,
          .text {
            flex: 1;
            color: #fff;
          }

          .id,
          .date {
            font-weight: 400;
          }

          .date {
            color: rgba(255, 255, 255, .7)
          }

          .text {
            font-weight: 500;
          }

          .amount {
            font-weight: 600;
            color: rgba(255, 215, 74, 1);
          }
        }

      }
    }
  }
}

/deep/.van-tab {
  font-weight: 400;
  font-size: 20px;
}

/deep/.van-tab--active {
  font-weight: 600;
}

/deep/.van-action-sheet__content {
  background: rgba(30, 35, 45, 1);
  padding: 0 20px;
}

/deep/.actionSheetContent {
  padding-top: 40px;
  color: #fff;

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    height: 28px;
    letter-spacing: 0px;
    text-align: left;
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .value {
    background: rgba(40, 42, 59, 1);
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    padding-left: 10px;
    line-height: 40px;
  }

  .inputBox {
    height: 40px;
    border-radius: 4px;
    background: rgba(20, 21, 30, 1);

    input {
      width: 100%;
      height: 100%;
      padding-left: 10px;
      line-height: 40px;
      font-size: 14px;
      background-color: transparent;
    }
  }

  .confirmBtn {
    margin: 0 auto;
    margin-top: 30px;
    width: 200px;
    height: 38px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    line-height: 38px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    background: rgba(82, 71, 116, 1);
  }
}

/deep/.van-button__text {
  color: #fff;
  font-weight: 600;
}
</style>
